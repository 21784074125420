"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const setCookie_1 = require("../../utils/cookies/setCookie");
const setCookieOrUpdateExpiration_1 = require("../../utils/cookies/setCookieOrUpdateExpiration");
const gtagConsent_1 = require("../../utils/gtagConsent");
(0, gtagConsent_1.initGtagConsent)();
setTimeout(() => {
    if (document.location.href.includes("utm_")) {
        (0, setCookie_1.setCookie)("first_visit", document.location.href, 30);
        (0, setCookieOrUpdateExpiration_1.setCookieOrUpdateExpiration)("first_visit_date", new Date().toISOString(), 30);
    }
}, 1000);
